import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import Modal from 'react-responsive-modal'

class Player extends Component {
  render() {
    const { open, toggleModal, url } = this.props
    return (
      <Modal
        open={open}
        onClose={toggleModal}
        closeIconSize={60}
        center
        styles={{
          modal: {
            maxWidth: 'unset',
            width: '100%',
            padding: 'unset',
            background: 'black'
          },
          overlay: {
            background: 'rgba(0, 0, 0, 0.8)'
          },
          closeButton: {
            background: 'transparent',
            outline: 'none',
            cursor: 'pointer',
            border: 'none'
          },
          closeIcon: {
            fill: 'red'
          }
        }}>
        <ReactPlayer url={url} width="100%" height="calc(100vh - 50px)" playing />
      </Modal>
    )
  }
}

export default Player
