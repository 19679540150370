import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import IconStar from '../images/sk-star-red.png'

export const Star = styled.div`
  background: url('${IconStar}') no-repeat center center;
  position: fixed;
  top: 50%;
  right: -16.66vh;
  height: 33vh;
  width: 33vh;
  background-size: 100%;
  margin-top: -16vh;
  z-index: 100;
  animation-name: spin;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  ${breakpoint('mobile')`
    display: none;
  `};
  ${breakpoint('tablet')`
    display: block;
  `};
`
