import React from 'react'
// A THREE.js React renderer, see: https://github.com/drcmda/react-three-fiber
import { Canvas, useThree } from 'react-three-fiber'
// A React animation lib, see: https://github.com/react-spring/react-spring
import { useSpring } from 'react-spring/three'
import { useTransition, animated } from 'react-spring'
import { useLocation, Switch, Route } from 'wouter'
import { debounce } from 'lodash-es'
import { BrowserView, MobileView } from 'react-device-detect'

// framework components
import VirtualScroll from './framework/VirtualScroll'

// 3D components
import VideoBackground from './components/VideoBackground'
import Background from './components/Background'
import Stars from './components/Stars'
import { Effects } from './components/Effects'

// ui components
import AudioBackground from './components/AudioBackground'
import Crosshair from './components/Crosshair'
import { Star } from './components/Star'
import { Menu } from './components/Menu'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { VideoBackgroundMobile } from './components/VideoBackgroundMobile'

// pages
import { Splash } from './pages/Splash'
import { Home } from './pages/Home'
import { Bio } from './pages/Bio'
import { Events } from './pages/Events'
import { Music } from './pages/Music'
import { News } from './pages/News'
import { NewsItem } from './pages/NewsItem'
import { Videos } from './pages/Videos'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import { NotFound } from './pages/NotFound'

// import data from './data'
import './styles.scss'
import './pillars.scss'

/** This component maintains the scene */
function Scene({ top, mouse }) {
  const { size } = useThree()
  const scrollMax = size.height * 4.5

  return (
    <>
      <VideoBackground opacity={top.interpolate([0, 200], [1, 0])} position={top.interpolate(top => [0, -1 + top / 200, 0])} />
      <Effects factor={top.interpolate([0, 150], [1, 0])} />
    </>
  )
}

/** UI component */
function ContentLayer({ enableScroll }) {
  return (
    <div className="ui">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/news" component={() => <News enableScroll={enableScroll} enableModal={false} />} />
        <Route exact path="/news/:slug" component={routeProps => <NewsItem {...routeProps} enableScroll={enableScroll} />} />
        <Route exact path="/bio" component={() => <Bio enableScroll={enableScroll} />} />
        <Route exact path="/events" component={() => <Events enableScroll={enableScroll} />} />
        <Route exact path="/music" component={() => <Music enableScroll={enableScroll} />} />
        <Route exact path="/videos" component={() => <Videos enableScroll={enableScroll} />} />
        <Route exact path="/merch" component={Merch} />
        <Route exact path="/outside-the-9" component={OutsideThe9} />
        <Route exact path="/knotfest" component={Knotfest} />
        <Route exact path="/mailinglist" component={MailingList} />
        <Route exact path="/privacypolicy" component={() => <PrivacyPolicy enableScroll={enableScroll} />} />
        <Route path="/:rest*" component={NotFound} />
      </Switch>
    </div>
  )
}

let index = 0

const Navigate = debounce(scrollDirection => {
  const routes = ['home', 'events', 'news', 'music', 'videos', 'bio', 'merch', 'mailinglist', 'outside-the-9', 'knotfest']

  if (scrollDirection === 'DOWN') {
    index = (index + 1) % routes.length
  } else if (scrollDirection === 'UP') {
    if (index === 0) {
      index = routes.length
    }
    index = (index - 1) % routes.length
  }

  let route = routes[index]
  let el = document.getElementById(route)
  el.click()
}, 250)

const BrowserSite = () => {
  const [{ top }, set] = useSpring(() => ({ top: 0 }))

  // enable and toggle virtual scroll
  // from child components to avoid
  // scroll during modal open
  let scroll = true

  function handleScroll(enabled) {
    scroll = enabled
  }

  const updateCurrentScroll = (scrollY, scrollDirection, delta) => {
    let lastScroll = -Math.round(scrollY)
    const threshold = 5

    set({ top: lastScroll })

    if (scroll && delta <= -threshold && scrollDirection === 'DOWN') {
      Navigate(scrollDirection)
      set({ top: 0 })
      window.scrollTo(0, 0)
    }

    if (scroll && delta >= threshold && scrollDirection === 'UP') {
      Navigate(scrollDirection)
    }
  }
  return (
    <>
      <VirtualScroll updateCurrentScroll={updateCurrentScroll}>
        <ContentLayer enableScroll={handleScroll} />
      </VirtualScroll>

      <Star />
      <Crosshair />
      <Menu />
      <Header />
      <Footer />
      <AudioBackground />
      <Background />
    </>
  )
}

const MobileSite = () => {
  function handleScroll(enabled) {
    return null
  }
  return (
    <>
      <ContentLayer enableScroll={handleScroll} />
      <Menu />
      <Footer />
      <Background />
    </>
  )
}

/** App component */
export default function App() {
  return (
    <>
      <BrowserView>
        <BrowserSite />
      </BrowserView>
      <MobileView>
        <MobileSite />
      </MobileView>
    </>
  )
}

const Merch = () => (
  <div className="page">
    <h1>Merch</h1>
    <a href="https://slipknotmerch.com/" target="_blank" rel="noopener noreferrer">
      VISIT MERCH STORE
    </a>
  </div>
)

const OutsideThe9 = () => (
  <div className="page">
    <h1>Outside The 9</h1>
    <a href="https://outsidethe9.com" target="_blank" rel="noopener noreferrer">
      JOIN OUTSIDE THE 9
    </a>
  </div>
)

const Knotfest = () => (
  <div className="page">
    <h1>Knotfest</h1>
    <a href="http://knotfestatsea.com" target="_blank" rel="noopener noreferrer">
      Knotfest At Sea
    </a>
    <a href="http://knotfestuk.com" target="_blank" rel="noopener noreferrer">
      Knotfest UK
    </a>
    <a href="http://knotfestroadshow.com" target="_blank" rel="noopener noreferrer">
      Knotfest USA
    </a>
    <a href="http://knotfestmx.com" target="_blank" rel="noopener noreferrer">
      Knotfest Mexico
    </a>
    <a href="http://knotfestfrance.com" target="_blank" rel="noopener noreferrer">
      Knotfest France
    </a>
    <a href="http://knotfestcolombia.com" target="_blank" rel="noopener noreferrer">
      Knotfest Colombia
    </a>
    <a href="http://knotfestjapan.com" target="_blank" rel="noopener noreferrer">
      Knotfest Japan
    </a>
  </div>
)

const MailingList = () => (
  <div className="page">
    <h1>Mailing List</h1>
    <a href="https://slipknot1.us19.list-manage.com/subscribe/post?u=bf49ef5330c5e1d8fc3cdc120&id=79278a2f57" target="_blank" rel="noopener noreferrer">
      JOIN THE MAILING LIST
    </a>
  </div>
)
