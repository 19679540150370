import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTrail, animated } from 'react-spring'
import breakpoint from 'styled-components-breakpoint'
import * as moment from 'moment'

import { ContentModal } from '../components/ContentModal'
import useFetch from '../framework/useFetch'
import { Link } from 'wouter'
import Config from '../config'

export const News = ({ enableScroll, enableModal }) => {
  const data = useFetch(`${Config.API}/wp/v2/posts?_embed&per_page=100`)
  const [toggle, setToggle] = useState(enableModal)

  // const trail = useTrail(posts, {
  //   opacity: toggle ? 1 : 0,
  //   y: toggle ? 0 : 20,
  //   from: { opacity: 0, y: 20 }
  // })

  const toggleModal = () => {
    setToggle(toggle => !toggle)
  }

  useEffect(() => {
    enableScroll(!toggle)
  })

  return (
    <>
      {!toggle && (
        <div className="page">
          <h1>News</h1>
          <button onClick={toggleModal}>View Latest News</button>
        </div>
      )}
      <ContentModal open={toggle} toggleModal={toggleModal} padding={false}>
        {data &&
          data.map((post, index) => (
            <StyledNews background={post._embedded ? post._embedded['wp:featuredmedia']['0'].source_url : ''} key={post.id}>
              <div className="header">
                <h2>
                  <Link href={`/news/${post.slug}`} dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                </h2>
                <h3>{moment(post.date).format('MMM DD, YYYY')}</h3>
                <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
              </div>
              <div className="overlay" />
            </StyledNews>
          ))}
      </ContentModal>
    </>
  )
}

const StyledNews = styled(animated.div)`
  position: relative;
  background: ${props => `url(${props.background}) no-repeat center center`};
  background-size: cover;
  height: 125vh;
  display: inline-block;
  text-align: center;
  overflow: hidden;

  ${breakpoint('mobile')`
    width: 100%;
  `};
  ${breakpoint('tablet')`
    width: 50%;
  `};

  .overlay {
    position: absolute;
    z-index: 0;
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .header {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;

    ${breakpoint('mobile')`
      padding: 0 1em;
    `};
    ${breakpoint('tablet')`
      padding: 0 2em;
    `};
    ${breakpoint('desktop')`
      padding: 0 4em;
    `};
  }

  h2 {
    color: red;
    text-transform: uppercase;

    ${breakpoint('mobile')`
      font-size: 18px;
    `};
    ${breakpoint('tablet')`
      font-size: 20px;
    `};
    ${breakpoint('desktop')`
      font-size: 30px;
    `};
  }

  h3 {
    color: white;
    text-transform: uppercase;

    ${breakpoint('mobile')`
      font-size: 14px;
    `};
    ${breakpoint('tablet')`
      font-size: 18px;
    `};
    ${breakpoint('desktop')`
      font-size: 24px;
    `};
  }

  p {
    font-size: 12px;
    line-height: 1.5;
    text-align: justify;
    color: lightgray;

    a,
    a:visited {
      color: red;
    }
  }

  ol {
    font-size: 12px;
    line-height: 1.5;
    color: lightgray;
    padding-inline-start: 0;
    text-align: left;
    position: relative;
    left: 50%;
    transform: translateX(-25%);
  }
`
