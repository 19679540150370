import React from 'react'
import styled from 'styled-components'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'

const StyledPrivacy = styled.div`
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20em 0;
  overflow: auto;

  font-size: 0.8em;

  a,
  a:visited {
    color: red !important;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const PrivacyPolicy = ({ enableScroll }) => {
  enableScroll(false)

  return (
    <StyledPrivacy>
      <Grid>
        <Row>
          <Col xs>
            <p>
              <strong>1. INTRODUCTION</strong>
            </p>
            <p>
              5B Artist Management (“5B Artist Management”” or “We”, “Our” or “Us”) care about Your privacy and We always handle Your personal information as
              set out in the European Union’s General Data Protection Regulation (”GDPR”). We are obliged to collect and process personal data in accordance
              with the GDPR, regardless whether Our users are based within the EU or not. We take Our obligations seriously and always collects personal data
              responsibly and with Your privacy in mind.
            </p>
            <p>
              This privacy policy explains how We process information that can be used to directly or indirectly identify an individual (”Personal Data”)
              collected through the use of Our website slipknot1.com (“Website”).
            </p>
            <p>Personal Data is defined in Article 4 (1) of the GDPR:</p>
            <p>
              <em>
                ‘personal data’ means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person
                is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number,
                location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or
                social identity of that natural person.
              </em>
            </p>
            <p>
              <strong>2. COLLECTION OF DATA</strong>
            </p>
            <p>
              The Website only collects, by itself or through third parties, Personal Data which has been voluntarily submitted by You (e-mail address) or
              collected through cookies or Your use of the Website.
            </p>
            <p>With your consent we collect Personal Data and Usage Data and use it for the purposes set out in the policy, see section 3.</p>
            <p>
              The term “Usage Data” shall mean information collected automatically through the use of the Website (or third-party services employed on this
              Website), which can include: the IP addresses or domain names of the computers utilized by the person who use the Website, the URI addresses
              (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in
              response, the numerical code indicating the status of the server’s answer (successful outcome, error, etc.), the country of origin, the features
              of the browser and the operating system utilized by You, the various time details per visit (e.g., the time spent on each page within the Website)
              and the details about the path followed within the Website with special reference to the sequence of pages visited, and other parameters about the
              device operating system and/or Your IT environment.
            </p>
            <p>
              <strong>3. PURPOSE OF AND LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA</strong>
            </p>
            <p>
              <em>The purposes of processing</em>
            </p>
            <p>We use Usage Data and Personal Data for the following purposes:</p>
            <p>– managing contacts</p>
            <p>– sending newsletters and messages</p>
            <p>– analytics and</p>
            <p>– remarketing and behavioral targeting (i.e. market Us to You in other channels through the use of pixels or cookies etc.)</p>
            <p>
              For more detailed information about such purposes and information of the specific Personal Data and Usage Data used for each purpose, see the
              relevant section below.
            </p>
            <p>
              <em>Legal basis of processing</em>
            </p>
            <p>
              Our legal basis of processing Usage Data and Personal Data for the purposes set out in this document and sharing such data with any third-party
              service providers listed below, is Your <strong>consent</strong>.
            </p>
            <p>3.1. Managing contacts and sending newsletters and messages</p>
            <p>We use Your email address to send You newsletters/messages and market Our products.</p>
            <p>We use MailChimp to administrate and send newsletter to the email addresses We collect.</p>
            <p>
              MailChimp is an email address management and message sending service provided by The Rocket Science Group, LLC. The handling of Your data through
              MailChimp is governed by Mail Chimps Privacy Policy that can be found here: – <a href="https://mailchimp.com/legal/privacy/">Privacy Policy</a>.
            </p>
            <p>Place of processing: United States – Privacy Shield participant.</p>
            <p>3.2. Remarketing and behavioral targeting</p>
            <p>This type of service allows Us and Our partners to inform, optimize and serve advertising based on past use of the Website by You.</p>
            <p>
              The above activity is performed by tracking Your Usage data and by using cookies. The collected information is transferred to the partners (listed
              below) that manage the remarketing and behavioral targeting activity.
            </p>
            <p>
              Some services offer a remarketing option based on email address lists. The service providers listed below may use cookies, web beacons, and other
              storage technologies to collect or receive information from Our Website and elsewhere on the internet and use that information to provide
              measurement services and target ads.
            </p>
            <p>
              In addition to any opt-out offered by any of the services below, You may at any time opt out of a third-party service’s use of cookies by visiting
              the <a href="http://optout.networkadvertising.org/">Network Advertising Initiative opt-out page.</a>
            </p>
            <p>
              <em>Facebook Custom Audience (Facebook, Inc.)</em>
            </p>
            <p>
              Facebook Custom Audience is a remarketing and behavioral targeting service provided by Facebook, Inc. that connects the activity of Our Website
              with the Facebook advertising network.
            </p>
            <p>Personal Data collected: cookies and email address.</p>
            <p>
              Place of processing: United States – <a href="https://www.facebook.com/about/privacy/">Privacy Policy</a> –{' '}
              <a href="https://www.aboutads.info/choices/">Opt Out</a>. Privacy Shield participant.
            </p>
            <p>3.3. Analytics</p>
            <p>The services contained in this section enable Us to monitor and analyze web traffic and can be used to keep track of a user’s behavior.</p>
            <p>
              <em>Google AdWords conversion tracking (Google Inc.)</em>
            </p>
            <p>
              Google AdWords conversion tracking is an analytics service provided by Google Inc. (“Google”) that connects data from the Google AdWords
              advertising network with actions performed on Our Website.
            </p>
            <p>Personal Data collected: cookies and Usage Data.</p>
            <p>
              Place of processing: United States – <a href="https://www.google.com/intl/en/policies/privacy/">Privacy Policy</a>. Privacy Shield participant.
            </p>
            <p>
              <em>Google Analytics (Google Inc.)</em>
            </p>
            <p>
              Google Analytics is a web analysis service provided by Google. Google utilizes the Personal Data and Usage Data collected to track and examine the
              use of our Website, to prepare reports on its activities and share them with other Google services.
            </p>
            <p>Google may use the Personal Data and Usage Data collected to contextualize and personalize the ads of its own advertising network.</p>
            <p>Personal Data collected: cookies and Usage Data.</p>
            <p>
              Place of processing: United States – <a href="https://www.google.com/intl/en/policies/privacy/">Privacy Policy</a> –{' '}
              <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</a>. Privacy Shield participant.
            </p>
            <p>
              <em>Facebook Ads conversion tracking (Facebook, Inc.)</em>
            </p>
            <p>
              Facebook Ads conversion tracking is an analytics service provided by Facebook, Inc. that connects data from the Facebook advertising network with
              actions performed on Our Website.
            </p>
            <p>Personal Data collected: cookies and Usage Data.</p>
            <p>
              Place of processing: United States – <a href="https://www.facebook.com/about/privacy/">Privacy Policy.</a> Privacy Shield participant.
            </p>
            <p>
              <em>Twitter Ads conversion tracking (Twitter, Inc.)</em>
            </p>
            <p>
              Twitter Ads conversion tracking is an analytics service provided by Twitter, Inc. that connects data from the Twitter advertising network with
              actions performed on Our Website.
            </p>
            <p>Personal Data collected: Cookies and Usage Data.</p>
            <p>
              Place of processing: United States – <a href="https://twitter.com/privacy">Privacy Policy</a>. Privacy Shield participant.
            </p>
            <p>3.4. Cookies</p>
            <p>We use information collected through cookies to:</p>
            <p>– to customize content</p>
            <p>– improve website functionality and</p>
            <p>– for the purposes set out above in this section 3.</p>
            <p>As we mentioned above, We use third party service providers, listed in this section 3, who may place cookies, webbeacons etc on Your device.</p>
            <p>
              By accepting this Privacy Policy You agree to Our use of cookies. For further, and more detailed, information about cookies and Our use of cookies
              see <a href="https://slipknot1.com/privacy">Our cookie policy.</a>
            </p>
            <p>
              <strong>4. SHARING AND TRANSFER OF PERSONAL DATA</strong>
            </p>
            <p>We may only disclose Usage Data and Personal Data insofar as reasonably necessary for the purposes set out above in this Privacy Policy.</p>
            <p>
              In some cases, the Usage Data and Personal Data may be accessible to certain types of persons in charge, involved with the operation of this
              Website (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers,
              mail carriers, hosting providers within the EU, IT companies, communications agencies) appointed, if necessary, as data processors by Us.
            </p>
            <p>We do NOT sell Personal Data to any third party without Your prior consent.</p>
            <p>
              <strong>5. HOW LONG DO WE KEEP PERSONAL DATA?</strong>
            </p>
            <p>In general, Personal Data shall be processed and stored for as long as required by the purpose it has been collected for.</p>
            <p>
              We may be allowed to retain Personal Data for a longer period if You have given consent to such processing, as long as such consent is not
              withdrawn. Furthermore, We may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal
              obligation or upon order of an authority.
            </p>
            <p>For the specific retention periods for the Personal Data collected about You, see below.</p>
            <p>E-mail address (for sending newsletters and marketing/remarketing)</p>
            <p>We will hold Your e-mail address for a period of six (6) years.</p>
            <p>You have the opportunity to opt out from receiving newsletters and marketing e-mails from Us.</p>
            <p>Cookies</p>
            <p>
              We keep cookies and information collected through the use of cookies in accordance with what is stated in{' '}
              <a href="https://slipknot1.com/privacy">Our cookie policy.</a>
            </p>
            <p>
              <strong>6. RECTIFICATION, ACCESS AND ERASURE OF PERSONAL DATA</strong>
            </p>
            <p>
              You may request access to and a copy of the Personal Data that We process in relation to You if You would like to know and verify the information
              We have on You.
            </p>
            <p>
              You have the right to correct inaccurate and/or incomplete information about Yourself. You have the right to be forgotten, which means You may
              request the deletion of Your Personal Data insofar as this Personal Data is no longer necessary for the purpose it was collected, or We have a
              legitimate interest to hold it. At Your request all our Personal Data will be erased as required by applicable law. You also have a right to
              withdraw any consent You have given, as well as ask Us to cease Our marketing efforts towards You.
            </p>
            <p>
              <strong>7. DATA PORTABILITY</strong>
            </p>
            <p>
              It is also Your right to receive the Personal Data which You have given to Us, in a structured, commonly used and machine-readable format and You
              have the right to transmit such Personal Data to another controller. This provision is applicable provided that the data is processed by automated
              means and that the processing is based on the Your consent.
            </p>
            <p>
              <strong>8. CHANGES TO THIS PRIVACY POLICY</strong>
            </p>
            <p>
              Please note that this Privacy Policy may change from time to time. If We change this Privacy Policy in ways that affect how We use Your personal
              information, We will advise You of the choices You may have as a result of those changes. We will also inform You of that this Policy Privacy has
              changed.
            </p>
            <p>
              <strong>9. CONTACT INFORMATION AND COMPLAINTS</strong>
            </p>
            <p>If You have any questions regarding this Privacy Policy or if You wish to exercise Your rights, please contact Us at:</p>
            <p>5B Artist Management</p>
            <p>Address: 12021 Jefferson Blvd, Culver City, CA 90230</p>
            <p>Phone number: 310-450-7132</p>
            <p>E-mail address: slipknot@5bam.com</p>
            <p>Representatives: Stephen Reeder</p>
            <p>
              If You have any complaints regarding this Privacy Policy or in the way We handle Your Personal Data You may always contact Your national security
              authority on data processing.
            </p>
            <h4>Slipknot – Cookie Policy</h4>
            <p>
              <em>See Slipknot’s privacy policy for definitions and terms concerning the processing etc of Your Personal Data.</em>
            </p>
            <p>
              <strong>1. COOKIES</strong>
            </p>
            <p>
              To make Our Website work properly, to analyze our visitor’s behavior on Our Website, and for marketing purposes We sometimes place small data
              files called cookies on Your device. Most websites do this too.
            </p>
            <p>
              <strong>2. WHAT ARE COOKIES</strong>
            </p>
            <p>
              A cookie (“Cookie”) is a small text file that a website saves on Your computer or mobile device when You visit the site. It enables the website to
              remember Your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so You don’t have
              to keep re-entering them whenever You come back to the site or browse from one page to another.
            </p>
            <p>
              Cookies are either deleted automatically when You turn off your web browser, so called “session cookies”, or are stored on Your computer to
              facilitate future visits on the website, so called “permanent cookies”. Permanent cookies should also be deleted/expire, either automatically or
              after a certain period.
            </p>
            <p>
              Read more about cookies at <a href="http://www.aboutcookies.org/">aboutcookies.org</a>.
            </p>
            <p>
              <strong>3. USE OF COOKIES</strong>
            </p>
            <p>
              <em>Necessary for the functions of the Website</em>
            </p>
            <p>
              This Website uses Cookies to save the User’s session and to carry out other activities that are strictly necessary for the operation of this
              Website, for example in relation to the distribution of traffic.
            </p>
            <p>
              <em>Cookies for optimization etc.</em>
            </p>
            <p>
              This Website uses Cookies to save browsing preferences and to optimize Your browsing experience. Among these Cookies are, for example, those used
              for the setting of language or for the management of first party statistics employed directly by Us.
            </p>
            <p>
              <strong>4. THIRD PARTY COOKIES </strong>
            </p>
            <p>
              Third party operated services listed below may be used to track Your browsing habits – in addition to the information specified herein and without
              Our knowledge.
            </p>
            <p>
              Since the installation of third-party Cookies and other tracking systems through the services used within this Website cannot be technically
              controlled by Us, any specific references to Cookies and tracking systems installed by third parties are to be considered indicative. In order to
              obtain complete information, We recommend that You consult the privacy policy for the respective third-party services listed in this document.
            </p>
            <p>Remarketing and behavioral targeting</p>
            <p>This type of service allows Us and Our partners to inform, optimize and serve advertising based on past use of the Website by You.</p>
            <p>
              The above activity is performed by tracking Your Usage Data and by using Cookies (which may contain Personal Data). The collected information is
              transferred to the partners (listed below) that manage the remarketing and behavioral targeting activity.
            </p>
            <p>
              Some services offer a remarketing option based on email address lists. The service providers listed below may use Cookies, web beacons, and other
              storage technologies to collect or receive information from Our Website and elsewhere on the internet and use that information to provide
              measurement services and target ads
            </p>
            <p>
              In addition to any opt-out offered by any of the services below, You may at any time opt out of a third-party service’s use of Cookies by visiting
              the <a href="http://optout.networkadvertising.org/?c=1#!/">Network Advertising Initiative opt-out page.</a>
            </p>
            <p>
              <em>Facebook Custom Audience (Facebook, Inc.)</em>
            </p>
            <p>
              Facebook Custom Audience is a remarketing and behavioral targeting service provided by Facebook, Inc. that connects the activity of Our Website
              with the Facebook advertising network.
            </p>
            <p>
              <a href="http://facebook.com/about/privacy">Privacy Policy</a> – <a href="http://aboutads.info/choices">Opt Out</a>. Privacy Shield participant.
            </p>
            <p>Analytics</p>
            <p>
              The services contained in this section enable Us to, with the use of Usage Data and Cookies (which may contain Personal Data), monitor and analyze
              web traffic and can be used to keep track of a user’s behavior.
            </p>
            <p>
              <em>Google AdWords conversion tracking (Google Inc.)</em>
            </p>
            <p>
              Google AdWords conversion tracking is an analytics service provided by Google Inc. (“Google”) that connects data from the Google AdWords
              advertising network with actions performed on Our Website.
            </p>
            <p>
              <a href="https://policies.google.com/privacy">Privacy Policy</a>. Privacy Shield participant.
            </p>
            <p>
              <em>Google Analytics (Google Inc.)</em>
            </p>
            <p>
              Google Analytics is a web analysis service provided by Google. Google utilizes the Personal Data and Usage Data collected to track and examine the
              use of our Website, to prepare reports on its activities and share them with other Google services.
            </p>
            <p>Google may use the Personal Data and Usage Data collected to contextualize and personalize the ads of its own advertising network.</p>
            <p>
              <a href="https://policies.google.com/privacy">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</a>. Privacy
              Shield participant.
            </p>
            <p>
              <em>Facebook Ads conversion tracking (Facebook, Inc.)</em>
            </p>
            <p>
              Facebook Ads conversion tracking is an analytics service provided by Facebook, Inc. that connects data from the Facebook advertising network with
              actions performed on Our Website.
            </p>
            <p>
              <a href="https://www.facebook.com/about/privacy/">Privacy Policy</a>. Privacy Shield participant.
            </p>
            <p>
              <em>Twitter Ads conversion tracking (Twitter, Inc.)</em>
            </p>
            <p>
              Twitter Ads conversion tracking is an analytics service provided by Twitter, Inc. that connects data from the Twitter advertising network with
              actions performed on Our Website.
            </p>
            <p>
              <a href="https://twitter.com/en/privacy">Privacy Policy</a>. Privacy Shield participant.
            </p>
            <p>
              <strong>5. OTHER THIRD PARTY COOKIES</strong>
            </p>
            <p>
              Be aware that advertisers and other third parties may use their own Cookie tags when You click on an advertisement or link on the Website. These
              third parties are responsible for setting out their own Cookie and privacy policies.
            </p>
            <p>
              <strong>6. IS INFORMATION TRANSFERRED TO THIRD PARTIES?</strong>
            </p>
            <p>Personal Data and Usage Data may be transferred to the third-party service providers listed in this cookie policy.</p>
            <p>
              In order to obtain complete information on how those third parties handles Your data, we recommend that You consult the privacy policy for the
              respective third-party services listed in this document.
            </p>
            <p>
              <strong>7. HOW LONG ARE THE COOKIES SAVED?</strong>
            </p>
            <p>
              Some Cookies are erased immediately after you have left the Website. Other Cookies are saved on Your device in order for our Website to remember
              your preferences such as language and for Us to be able to analyze Your behavior on our Website etc. These Cookies may be saved for several years
              on Your device.
            </p>
            <p>
              Third party Cookies, from our service providers, may be stored on Your device for days, months or even longer. For more detailed information
              regarding third-party cookies please read the relevant third party privacy policy listed in this cookie policy.
            </p>
            <p>
              <strong>8. HOW TO CONTROL COOKIES</strong>
            </p>
            <p>
              You can control and/or delete Cookies as You wish – for details, see <a href="http://www.aboutcookies.org/">aboutcookies.org</a>.
            </p>
            <p>
              You can delete all Cookies that are already on Your computer and You can set most browsers to prevent them from being placed. You can also prevent
              third parties from placing Cookies. If You do this, however, You may have to manually adjust some preferences every time You visit a site and some
              services and functionalities may not work.
            </p>
            <p>
              With regard to Cookies installed by third parties, Users can manage their preferences and withdrawal of their consent by clicking the related
              opt-out link (if provided), by using the means provided in the third party’s privacy policy, or by contacting the third party.
            </p>
          </Col>
        </Row>
      </Grid>
    </StyledPrivacy>
  )
}
