import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { useTrail, animated } from 'react-spring'
import { ContentModal } from '../components/ContentModal'
import useFetch from '../framework/useFetch'
import Config from '../config'

export const Music = ({ enableScroll }) => {
  const data = useFetch(`${Config.API}/wp/v2/music?_embed`)

  const [toggle, setToggle] = useState(false)

  // const trail = useTrail(albums.length, {
  //   opacity: toggle ? 1 : 0,
  //   y: toggle ? 0 : 20,
  //   from: { opacity: 0, y: 20 }
  // })

  const toggleModal = () => {
    setToggle(toggle => !toggle)
  }

  useEffect(() => {
    enableScroll(!toggle)
  })

  return (
    <>
      {!toggle && (
        <div className="page">
          <h1>Music</h1>
          <button onClick={toggleModal}>View Music</button>
        </div>
      )}
      <ContentModal open={toggle} toggleModal={toggleModal} padding={true}>
        <Grid>
          <Row>
            {data &&
              data.map((post, index) => (
                <Col xs={12} sm={6} md={3} key={index}>
                  <StyledAlbum>
                    <a href={post.acf.external_url} target="_blank" rel="noopener noreferrer">
                      <img src={post._embedded ? post._embedded['wp:featuredmedia']['0'].source_url : ''} alt={post.title.rendered} />
                      <div className="title">{post.title.rendered}</div>
                      <div className="year">{post.acf.year}</div>
                    </a>
                  </StyledAlbum>
                </Col>
              ))}
          </Row>
        </Grid>
      </ContentModal>
    </>
  )
}

const StyledAlbum = styled(animated.div)`
  text-align: center;
  text-transform: uppercase;
  margin: 1em auto;
  cursor: pointer;

  a,
  a:visited {
    outline: none;
  }

  img {
    margin-bottom: 1em;
  }

  .title,
  .year {
    font-size: 0.75em;
  }
`
