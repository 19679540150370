import React, { useState, useEffect } from 'react'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import useAxios from 'axios-hooks'
import * as moment from 'moment'
import { ContentModal } from '../components/ContentModal'

export const Events = ({ enableScroll }) => {
  const apiUrl = 'https://cdn.seated.com/api/public/v1/artists/f76ce370-4dfb-45a0-81d1-4e33ff563317/tour-events'
  const apiKey = 'b13de94f-02b4-4fb6-ab67-e194d5b050ad'

  const [{ data, loading, error }, refetch] = useAxios({ url: apiUrl, headers: { Authorization: apiKey } })
  const [toggle, setToggle] = useState(true)

  const toggleModal = () => {
    setToggle(toggle => !toggle)
  }

  useEffect(() => {
    enableScroll(!toggle)
  })

  return (
    <>
      {!toggle && (
        <div className="page">
          <h1>Events</h1>
          <button onClick={toggleModal}>View Events</button>
        </div>
      )}
      <ContentModal open={toggle} toggleModal={toggleModal}>
        <Grid>
          {loading && (
            <Row middle="xs">
              <Col xs>Loading Tour Dates...</Col>
            </Row>
          )}
          {error && (
            <Row middle="xs">
              <Col xs>Error Loading Tour Dates</Col>
            </Row>
          )}
          {!loading &&
            !error &&
            data &&
            data.data.map(event => (
              <StyledEvent as={Row} key={event.id} between="xs" middle="xs">
                <Col xs md={4}>
                  <StyledDate>{moment(event.attributes['starts-at-date-local']).format('MMM DD, YYYY')}</StyledDate>
                  <StyledVenue>{event.attributes['venue-name']}</StyledVenue>
                </Col>
                <Col xs md={4} xsOffset={1}>
                  <div>{event.attributes['venue-formatted-address']}</div>
                </Col>
                <Col xs={12} md={1}>
                  {event.attributes['promoted-button-text'] ? (
                    <StyledTicketButton href={event.attributes['promoted-link-url']} target="_blank">
                      {event.attributes['promoted-button-text']}
                    </StyledTicketButton>
                  ) : null}
                </Col>
                <Col xs={12} md={2}>
                  <StyledTicketButton href={event.attributes['primary-link-url']} target="_blank">
                    {event.attributes['primary-button-text']}
                  </StyledTicketButton>
                </Col>
              </StyledEvent>
            ))}
        </Grid>
      </ContentModal>
    </>
  )
}

const StyledEvent = styled.div`
  margin: 2em auto;
`

const StyledDate = styled.div`
  display: block;
`

const StyledVenue = styled.div`
  display: block;
  margin: 0.5em auto;
  font-size: 0.75em;
  opacity: 0.75;
`

const StyledLocation = styled.div``

const StyledTicketButton = styled.a`
  display: block;
  background: red;
  color: white;
  border: 1px solid transparent;
  padding: 0.5em 1em 0.7em;
  transition: 0.3s;
  text-align: center;
  line-height: 1;

  &:hover {
    background: transparent;
    color: white;
    border-color: white;
  }
`
