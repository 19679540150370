import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'wouter'
import ReactHowler from 'react-howler'
import breakpoint from 'styled-components-breakpoint'

import IconAudio from '../images/icon-audio.png'

const StyledMute = styled.button`
  background: transparent url(${IconAudio}) no-repeat center center;
  height: 20px;
  width: 20px;
  background-size: 100%;
  border: none;

  position: fixed;
  z-index: 50;
  outline: none;
  transition: 0.2s;
  cursor: pointer;

  ${breakpoint('mobile')`
    top: 2.5em;
    right: 5em;
  `};
  ${breakpoint('tablet')`\
    top: auto;
    right: auto;
    bottom: 3em;
    left: 5vw;
  `};

  &.muted {
    opacity: 0.5;
    &::after {
      content: '';
      background: white;
      position: absolute;
      top: 10px;
      right: -10px;
      height: 2px;
      width: 40px;
      transform: rotate(-45deg);
    }
  }
`

export default function AudioBackground() {
  const [mute, set] = useState(false)
  const [location] = useLocation()

  let source

  switch (location) {
    case '/':
      source = './static/audio/loop9'
      break
    case '/news':
      source = './static/audio/nero-forte'
      break
    case '/bio':
      source = './static/audio/not-long-for-this-world'
      break
    case '/events':
      source = './static/audio/orphan'
      break
    case '/music':
      source = './static/audio/loop1'
      break
    case '/videos':
      source = ''
      break
    case '/merch':
      source = './static/audio/unsainted'
      break
    case '/outside-the-9':
      source = './static/audio/solway-firth'
      break
    case '/knotfest':
      source = './static/audio/spiders'
      break
    default:
      source = ''
  }

  return (
    <>
      <StyledMute className={mute ? 'muted' : ''} onClick={() => set(state => !state)} />
      <ReactHowler mute={mute} src={[`${source}.mp3`]} playing loop />
    </>
  )
}
