import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { ContentModal } from '../components/ContentModal'
import useFetch from '../framework/useFetch'
import Config from '../config'

export const Bio = ({ enableScroll }) => {
  const data = useFetch(`${Config.API}/acf/v3/options/option`)
  const [toggle, setToggle] = useState(false)

  const toggleModal = () => {
    setToggle(toggle => !toggle)
  }

  useEffect(() => {
    // console.log(`modal toggle is: ${toggle}`)
    enableScroll(!toggle)
  })

  return (
    <>
      {!toggle && (
        <div className="page">
          <h1>Bio</h1>
          <button onClick={toggleModal}>View Biography</button>
        </div>
      )}
      {data && (
        <ContentModal open={toggle} toggleModal={toggleModal} padding={false}>
          <StyledBio>
            <Grid>
              <Row>
                <Col xs={12} sm={6}>
                  <img src={data.acf.biography_photo} alt="Slipknot" />
                </Col>
                <Col xs={false} sm={1} />
                <Col xs={12} sm={5}>
                  <div dangerouslySetInnerHTML={{ __html: data.acf.biography }} />
                </Col>
              </Row>
            </Grid>
          </StyledBio>
        </ContentModal>
      )}
    </>
  )
}

const StyledBio = styled.div`
  padding: 10vh 0;

  img {
    margin-bottom: 5vh;
  }

  p {
    font-size: 12px;
    line-height: 1.5;
    text-align: justify;
    color: lightgray;

    a,
    a:visited {
      color: red;
    }
  }
`
