import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import breakpoint from 'styled-components-breakpoint'
import { ContentModal } from '../components/ContentModal'
import { Link } from 'wouter'
import nero from '../images/nero-cover.jpg'
import bgImg from '../images/pollution-bg.jpg'
import poster from '../images/knotfest-at-sea.jpg'
import logo from '../images/sk-logo-red.png'

export const Splash = ({ enableScroll }) => {
  useEffect(() => {
    enableScroll(false)
  })

  return (
    <ContentModal open={true} padding={true}>
      <StyledSplash>
        <Grid>
          <Row center="xs">
            <Col xs={6} sm={3}>
              <img src={logo} className="logo" />
            </Col>
          </Row>
          <Row center="xs">
            <Col xs={12} sm={12}>
              <a href="https://www.youtube.com/watch?v=JGNqvH9ykfA" target="_blank">
                <img src={nero} className="thumb" />
              </a>
            </Col>
          </Row>

          <Row center="xs">
            <Col xs={12} sm={4}>
              <a href="/home" class="cta-button">
                ENTER SITE
              </a>
            </Col>

            <Col xs={12} sm={4}>
              <a href="http://knotfestatsea.com" target="_blank" class="cta-button">
                KNOTFEST AT SEA
              </a>
            </Col>
            <Col xs={12} sm={4}>
              <a href="https://www.knotfestuk.com" target="_blank" class="cta-button">
                KNOTFEST UK
              </a>
            </Col>
          </Row>
        </Grid>
      </StyledSplash>
    </ContentModal>
  )
}

const StyledSplash = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: black url(${bgImg});
  background-attachment: fixed;
  background-size: cover;
  z-index: 200;
  text-align: center;
  padding: 2rem 0 10rem;

  a,
  a:visited {
    outline: none;
    color: red;
  }

  .cta-button {
    color: white !important;
    background: red;
    border: 0;
    padding: 20px 30px;
    display: inline-block;
    margin: 2rem auto;

    &:hover {
      background: darkred;
    }
  }

  .enter-site {
    margin: 2rem auto;
  }

  .logo {
    margin: 2rem auto 0 auto;
    display: block;
  }

  .thumb {
    margin: 2rem auto 0 auto;
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin: 2rem auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
