import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const StyledHeader = styled.footer`
  position: fixed;
  top: 3em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  text-transform: uppercase;
  font-size: 0.5em;
  color: white;

  ${breakpoint('mobile')`
    width: 100%;
  `};
  ${breakpoint('tablet')`
    width: auto;
  `};
`

export const Header = () => {
  return (
    <StyledHeader>
      <div className="cta">Scroll to browse site</div>
    </StyledHeader>
  )
}
