import React from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import breakpoint from 'styled-components-breakpoint'
import * as moment from 'moment'
import { ContentModal } from '../components/ContentModal'
import useFetch from '../framework/useFetch'
import { useLocation, Link } from 'wouter'
import { Helmet } from 'react-helmet'
import Config from '../config'

export const NewsItem = ({ enableScroll, ...props }) => {
  const data = useFetch(`${Config.API}/wp/v2/posts?slug=${props.params.slug}&_embed`)
  const [location, setLocation] = useLocation()

  return (
    <>
      {data && (
        <ContentModal open={true} toggleModal={() => setLocation('/news')} padding={false}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{data[0].title.rendered}</title>
            <link rel="canonical" href={`/news/${data[0].slug}`} />
            <meta name="description" content={data[0].excerpt} />

            <meta property="og:url" content={`/news/${data[0].slug}`} />
            <meta property="og:title" content={data[0].title.rendered} />
            <meta property="og:description" content={data[0].excerpt} />
            <meta property="og:image" content={data[0]._embedded ? data[0]._embedded['wp:featuredmedia']['0'].source_url : ''} />
          </Helmet>

          <StyledNews key={data.slug}>
            <Grid>
              <Row>
                <Col xs={12} sm={5}>
                  <StyledMarquee background={data[0]._embedded ? data[0]._embedded['wp:featuredmedia']['0'].source_url : ''} />
                </Col>
                <Col xs={12} sm={6} smOffset={1}>
                  <div className="header">
                    <h2>{data[0].title.rendered}</h2>
                    <h3>{moment(data[0].date).format('MMM DD, YYYY')}</h3>
                    <div dangerouslySetInnerHTML={{ __html: data[0].content.rendered }} />
                  </div>
                </Col>
              </Row>
            </Grid>
          </StyledNews>
        </ContentModal>
      )}
    </>
  )
}

const StyledMarquee = styled.div`
  background: ${props => `url(${props.background}) no-repeat center center`};
  background-size: 100%;
  width: 100%;

  ${breakpoint('mobile')`
    height: 50vh;
  `};
  ${breakpoint('tablet')`
    height: 60vh;
  `};
  ${breakpoint('desktop')`
    height: 100vh;
  `};
`

const StyledNews = styled(animated.div)`
  background-size: cover;
  display: inline-block;
  text-align: center;
  padding-bottom: 50px;
  width: 100%;

  .header {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    ${breakpoint('mobile')`
      padding: 0 1em;
    `};
    ${breakpoint('tablet')`
      padding: 0 2em;
    `};
    ${breakpoint('desktop')`
      padding: 0 2em;
    `};
  }

  h2 {
    color: red;
    text-transform: uppercase;

    ${breakpoint('mobile')`
      font-size: 18px;
    `};
    ${breakpoint('tablet')`
      font-size: 20px;
    `};
    ${breakpoint('desktop')`
      font-size: 30px;
    `};
  }

  h3 {
    color: white;
    text-transform: uppercase;

    ${breakpoint('mobile')`
      font-size: 14px;
    `};
    ${breakpoint('tablet')`
      font-size: 18px;
    `};
    ${breakpoint('desktop')`
      font-size: 24px;
    `};
  }

  p {
    font-size: 12px;
    line-height: 1.5;
    text-align: justify;
    color: lightgray;

    a,
    a:visited {
      color: red;
    }
  }

  ol {
    font-size: 12px;
    line-height: 1.5;
    color: lightgray;
    padding-inline-start: 0;
    text-align: left;
    position: relative;
    left: 50%;
    transform: translateX(-25%);
  }
`
