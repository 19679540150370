import React from 'react'
import { useLocation } from 'wouter'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const StyledContainer = styled.div`
  background: ${props => `url(${props.bg}) no-repeat center center`};
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default function Background() {
  const [location] = useLocation()

  const backgrounds = [
    './static/videos/news/bio.gif',
    './static/videos/news/news.gif',
    './static/videos/news/bio.gif',
    './static/videos/news/events.gif',
    './static/videos/news/music.gif',
    './static/videos/news/videos.gif',
    './static/videos/news/merch.gif',
    './static/videos/news/ot9.gif',
    './static/videos/news/knotfest1.gif',
    './static/videos/news/knotfest2.gif',
    './static/videos/l0-min.gif'
  ]

  let source

  switch (location) {
    default:
      source = backgrounds[Math.floor(Math.random() * backgrounds.length)]
      console.log(source)
  }

  return <StyledContainer bg={source} />
}
