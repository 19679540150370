import React, { useState } from 'react'
import styled from 'styled-components'
import GlitchEffect from 'react-glitch-effect'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import breakpoint from 'styled-components-breakpoint'
import Tilt from 'react-tilt'
import { ContentModal } from '../components/ContentModal'
import Player from '../components/Player'

import { isMobile } from 'react-device-detect'

import IconAmazon from '../images/icon-amazon.png'
import IconSpotify from '../images/icon-spotify.png'
import IconDeezer from '../images/icon-deezer.png'
import IconItunes from '../images/icon-itunes.png'
import IconStore from '../images/icon-store.png'
import Gabrielle from '../images/gabrielle2.jpg'

export const Home = ({ enableScroll }) => {
  const [toggle, setToggle] = useState(false)
  const [toggle2, setToggle2] = useState(false)
  const [toggle3, setToggle3] = useState(false)
  const [video, setVideo] = useState(false)
  const [video2, setVideo2] = useState(false)
  const [video3, setVideo3] = useState(false)

  const toggleModal = () => {
    setToggle(toggle => !toggle)
  }

  const toggleModal2 = () => {
    setToggle(toggle2 => !toggle2)
  }

  const toggleModal3 = () => {
    setToggle(toggle3 => !toggle3)
  }

  const toggleVideo = () => {
    setVideo(video => !video)
  }

  const toggleVideo2 = () => {
    setVideo2(video2 => !video2)
  }

  const toggleVideo3 = () => {
    setVideo3(video3 => !video3)
  }

  const featuredVideo = 'https://www.youtube.com/watch?v=JGNqvH9ykfA'
  const featuredVideo2 = 'https://www.youtube.com/watch?v=VpATBBRajP8'
  const featuredVideo3 = 'https://www.youtube.com/watch?v=V3ADK6gsDGg'

  const items = [
    
      <h1><a href="https://slipknot1.lnk.to/wanyk/" target="_blank">WE ARE NOT YOUR KIND</a></h1>,
    <h3>
      <a href="https://slipknot1.lnk.to/wanyk/applemusic" target="_blank" rel="noopener noreferrer">
        OUT NOW
      </a>
    </h3>,
    <ul className="purchase-options">
      <li>
        <a href="https://slipknot1.lnk.to/wanyk/applemusic" target="_blank" rel="noopener noreferrer">
          <GlitchEffect onHover={true} disabled={isMobile}>
            <img src={IconItunes} alt="itunes" />
          </GlitchEffect>
        </a>
      </li>
      <li>
        <a href="https://slipknot1.lnk.to/wanyk/spotify" target="_blank" rel="noopener noreferrer">
          <GlitchEffect onHover={true} disabled={isMobile}>
            <img src={IconSpotify} alt="spotify" />
          </GlitchEffect>
        </a>
      </li>
      <li>
        <a href="https://slipknot1.lnk.to/wanyk/amazon" target="_blank" rel="noopener noreferrer">
          <GlitchEffect onHover={true} disabled={isMobile}>
            <img src={IconAmazon} alt="amazon" />
          </GlitchEffect>
        </a>
      </li>
      <li>
        <a href="https://slipknot1.lnk.to/wanyk/deezer" target="_blank" rel="noopener noreferrer">
          <GlitchEffect onHover={true} disabled={isMobile}>
            <img src={IconDeezer} alt="deezer" />
          </GlitchEffect>
        </a>
      </li>
      <li>
        <a href="https://slipknot1.lnk.to/wanyk/artiststore" target="_blank" rel="noopener noreferrer">
          <GlitchEffect onHover={true} disabled={isMobile}>
            <img src={IconStore} alt="store" />
          </GlitchEffect>
        </a>
      </li>
    </ul>
  ]

  return (
    <>
      <StyledHome>
        {!toggle && <Player url={featuredVideo} open={video} toggleModal={toggleVideo} />}
        {!toggle2 && <Player url={featuredVideo2} open={video2} toggleModal={toggleVideo2} />}
        {!toggle3 && <Player url={featuredVideo3} open={video3} toggleModal={toggleVideo3} />}
        <Grid>
          <Row center="xs" middle="xs">
           <Col xs={12} sm={5}>
       <Tilt options={{ max: 100, scale: 1.015 }}>          
              <a href="https://slipknot1.lnk.to/wanyk/" target="_blank">
     
      <img className="album" src="./static/images/01_Slipknot_WANYK.jpg" alt="WANYK" />
              </a>
       </Tilt>
            </Col>
     
            <Col xs={12} sm={7}>
              {items.map(item => item)}
            </Col>
          </Row>
        </Grid>
      </StyledHome>

      <ContentModal open={toggle} toggleModal={toggleModal} padding={false}>
        <StyledPop>
          <Grid className="inner">
            <Row center="xs" middle="xs">
              <Col xs={12} sm={5} />
              <Col xs={false} sm={1} />
              <Col xs={12} sm={6} />
            </Row>
          </Grid>
        </StyledPop>
      </ContentModal>
    </>
  )
}

const StyledHome = styled.div`
  text-align: center;

  img {
    max-width: 100%;

    &.album {
      ${breakpoint('mobile')`
        display: block;
        margin-bottom: 1em;
      `};
    }
  }



  h1 {
    line-height: 1.2;
    margin-bottom: 0.5em;
    font-weight: 300;
    font-family: 'Industry Book';

    ${breakpoint('mobile')`
     font-size: 17px;
    `};
    ${breakpoint('tablet')`
      font-size: 1.5em;
    `};
    ${breakpoint('desktop')`
     font-size: 2.5em;
    `};
  }

  h2 {
    margin: 1em auto 0.25em;
    font-weight: 300;
    font-family: 'Industry Book';

    ${breakpoint('mobile')`
     font-size: 15px;
    `};
    ${breakpoint('tablet')`
      font-size: 1em;
    `};
    ${breakpoint('desktop')`
     font-size: 2em;
    `};
  }

  h3 {
    line-height: 1;
    margin: 0 auto 2em;
    color: red;
    cursor: pointer;
    ${breakpoint('mobile')`
     font-size: 13px;
    `};
    ${breakpoint('tablet')`
      font-size: 1em;
    `};
    ${breakpoint('desktop')`
    font-size: 1.5em;
    `};
  }

  a {
    color: red;
  }

h1 a {
color: $white;
}

  .top-pad {
    margin-top: 40px;
  }
  .purchase-options {
    margin: 2em auto;
    margin-left: 0;
    padding-left: 0;

    li {
      display: inline-block;
      max-width: 30px;
      margin: 0 1em;
      cursor: pointer;
    }
  }
`
const StyledPop = styled.div`
  height: 100vh;

  .inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  p {
    font-size: 15px;
    line-height: 1.5;
    text-align: left;
    color: lightgray;

    a,
    a:visited {
      color: red;
    }
  }
`
