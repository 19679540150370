import React from 'react'
import ReactPlayer from 'react-player'
import { useLocation } from 'wouter'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
    opacity: 0.8;
  }
`

export const VideoBackgroundMobile = () => {
  const [location] = useLocation()

  let source

  switch (location) {
    case '/':
      source = './static/videos/l0.mp4'
      break
    case '/news':
      source = './static/videos/l1.mp4'
      break
    case '/bio':
      source = './static/videos/l3.mp4'
      break
    case '/events':
      source = './static/videos/l4.mp4'
      break
    case '/music':
      source = './static/videos/l5.mp4'
      break
    case '/videos':
      source = './static/videos/l6.mp4'
      break
    case '/merch':
      source = './static/videos/l7.mp4'
      break
    case '/outside-the-9':
      source = './static/videos/l8.mp4'
      break
    case '/knotfest':
      source = './static/videos/l9.mp4'
      break
    case '/mailinglist':
      source = './static/videos/l2.mp4'
      break
    default:
      source = './static/videos/l1.mp4'
  }

  return (
    <StyledWrapper>
      <ReactPlayer url={source} height="100vh" width="100vw" playing playsinline muted loop />
    </StyledWrapper>
  )
}
