import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { Link } from 'wouter'
import GlitchEffect from 'react-glitch-effect'
import { isMobile } from 'react-device-detect'

import IconAmazon from '../images/social-amazon.png'
import IconApple from '../images/social-apple.png'
import IconFacebook from '../images/social-facebook.png'
import IconInstagram from '../images/social-instagram.png'
import IconSpotify from '../images/social-spotify.png'
import IconTwitter from '../images/social-twitter.png'
import IconYoutube from '../images/social-youtube.png'

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 3em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  text-transform: uppercase;
  font-size: 0.5em;
  color: white;
  text-align: center;

  ${breakpoint('mobile')`
    width: 90%;
    margin: 0 auto;
  `};
  ${breakpoint('tablet')`
    width: auto;
  `};
`


const StyledSocialLinks = styled.div`

${breakpoint('mobile')`
    display: none;
  `};
  ${breakpoint('tablet')`
   display: block;
  `};


  ul {
    list-style: none;
    margin: 2em auto;
    padding: 0;



    li {
      display: inline-block;
      margin: 0 1em;

      img {
        width: 20px;
      }
    }
  };
`

export const Footer = () => {
  return (
    <StyledFooter>
      <StyledSocialLinks>
        <ul>
          <li>
            <a href="https://www.amazon.com/Slipknot/e/B000APLMDY/digital/ref=ntt_mp3_rdr?_encoding=UTF8&sn=d" target="_blank" rel="noopener noreferrer">
              <GlitchEffect onHover={true} disabled={isMobile}>
                <img src={IconAmazon} alt="Amazon" />
              </GlitchEffect>
            </a>
          </li>
          <li>
            <a href="https://itunes.apple.com/us/artist/slipknot/6907568" target="_blank" rel="noopener noreferrer">
              <GlitchEffect onHover={true} disabled={isMobile}>
                <img src={IconApple} alt="Apple" />
              </GlitchEffect>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/slipknot" target="_blank" rel="noopener noreferrer">
              <GlitchEffect onHover={true} disabled={isMobile}>
                <img src={IconFacebook} alt="Facebook" />
              </GlitchEffect>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/slipknot/" target="_blank" rel="noopener noreferrer">
              <GlitchEffect onHover={true} disabled={isMobile}>
                <img src={IconInstagram} alt="Instagram" />
              </GlitchEffect>
            </a>
          </li>
          <li>
            <a href="https://open.spotify.com/user/slipknotofficial" target="_blank" rel="noopener noreferrer">
              <GlitchEffect onHover={true} disabled={isMobile}>
                <img src={IconSpotify} alt="Spotify" />
              </GlitchEffect>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/slipknot" target="_blank" rel="noopener noreferrer">
              <GlitchEffect onHover={true} disabled={isMobile}>
                <img src={IconTwitter} alt="Twitter" />
              </GlitchEffect>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/user/slipknot" target="_blank" rel="noopener noreferrer">
              <GlitchEffect onHover={true} disabled={isMobile}>
                <img src={IconYoutube} alt="Youtube" />
              </GlitchEffect>
            </a>
          </li>
        </ul>
      </StyledSocialLinks>
      &copy; 2020 Slipknot. All Rights Reserved.
      <p>
        <Link href="/privacypolicy">Privacy Policy</Link>
      </p>
    </StyledFooter>
  )
}
